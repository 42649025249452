import $ from 'cash-dom'
import { getCart, addItemsToCart } from '../resources/shopify-cart'
import { generateUID, getURLParam } from '../utilities/toolkit'
import { cart } from './cart'
import { setCollectionHandle } from '../pages/collection'
import { showSelectionPanel, hideSelectionPanel } from './BYS-selection-panel'
import { setBYSSearchTemplate } from './BYS-search'
import { getNewPackingId } from '../utilities/cart-packing-id'
import { updateTrademark } from '../utilities/trademark.ts'
import { showToast } from './toast'
import { searchProducts } from '../resources/shopify-search'
import { openProductPageModal } from './product-page-modal.ts'

class BuildYourSet {
  constructor(maxProducts) {
    const _ = this
    // currentStep indexs are 1 and 2,
    // they don't start at 0 to keep naming consistent
    _.currentStep = 1

    // selected products array
    // we only use indexes 1 and 2
    _.selectedProducts = [[], [], []]

    // for product quick view panel
    _.selectedProduct = {}

    _.bindUI()
  }

  bindUI() {
    const _ = this

    const preSelected = getURLParam('selected') || false
    if (preSelected) _.updatePreSelected(preSelected)

    // Show details panel
    document.addEventListener('click', (e) => {
      if (!e.target.closest('[data-action="show-BYS-selection-panel"]')) return

      // get product data
      const productThumbnail = e.target.closest(
        '[data-action="show-BYS-selection-panel"]'
      )
      const productId = productThumbnail.getAttribute('data-product-id')
      _.selectedProduct = JSON.parse($(`#product-data-${productId}`).html())

      // show panel, pass in data
      showSelectionPanel(_.selectedProduct, _.currentStep)
    })

    $('[data-element="bys-device-image"]').on('click', (e) => {
      const handle = $(e.currentTarget).data('handle')
      const product = Pura?.product
      openProductPageModal($('#product-page-modal'), handle, product)
    })

    $('[data-component="BYS-selected-product"]').on('click', (e) => {
      if ($(e.target).closest('[data-action="remove-product-from-BYS"]').length)
        return

      const selected = $(e.currentTarget)
      const isActive = selected.hasClass('is-active')
      const selectedIndex = $(e.currentTarget).data('index')

      if (isActive) {
        const handle = selected.data('handle')
        const products = _.selectedProducts[1] || []
        const matchingProducts = products.filter((p) => p.handle === handle)
        const product =
          matchingProducts.length > 1
            ? matchingProducts[selectedIndex]
            : matchingProducts[0]

        if (product) {
          openProductPageModal($('#product-page-modal'), handle, product)
        }
      } else {
        $('#collection-menu-filter-buttons').get(0).scrollIntoView({
          behavior: 'smooth',
        })
      }
    })

    // Add product to boxset
    $('[data-action="add-selected-product-to-BYS"]').on('click', (e) => {
      e.preventDefault()

      let selectedProducts = _.selectedProducts[_.currentStep]

      // get max products
      let maxProducts = Pura.BYS.panel_1_max_products
      if (_.currentStep == 2) {
        maxProducts = Pura.BYS.panel_2_max_products
      }

      // remove last item if already at max
      if (selectedProducts.length == maxProducts) {
        selectedProducts.pop()
      }

      // check for OTP or SUB purchase type
      let purchaseType = $(
        '#product-selection-panel [name="purchase-type"]:checked'
      ).val()

      if (purchaseType != null) {
        // save the purchase type
        _.selectedProduct.purchaseType = purchaseType

        if (purchaseType == 'SUB') {
          // add sub frequency
          _.selectedProduct.subFrequency = $(
            '#product-selection-panel #sub-interval-frequency'
          ).val()
        }
      }

      // pushing product to selected products array
      selectedProducts.push(_.selectedProduct)

      // update header content
      _.updateHeaderContent()
      // hide panel
      hideSelectionPanel()
      // scroll to top of page
      window.scrollTo(0, 0)
    })

    // Remove product from boxset
    $('[data-action="remove-product-from-BYS"]').on('click', (e) => {
      e.preventDefault()
      let _ = this

      let productsList = _.selectedProducts[_.currentStep]
      // get closest component
      let component = e.target.closest(
        '[data-component="BYS-selected-product"]'
      )

      // remove product from the array
      if (component.getAttribute('data-index') == '0') {
        productsList.splice(0, 1)
      } else {
        productsList.splice(1, 1)
      }
      _.updateHeaderContent()
    })

    $('[data-action="BYS-goto-step-2"]').on('click', (e) => {
      _.gotoStep2()
    })

    $('[data-action="BYS-goto-step-1"]').on('click', (e) => {
      _.gotoStep1()
    })

    // Add bundle to cart
    $('[data-action="add-BYS-to-cart"]').on('click', (e) => {
      e.preventDefault()
      getCart().then((cartData) => {
        _.addToCart(cartData)
      })
    })

    _.updateSearchTemplate()
  } // END bind UI

  updateHeaderContent() {
    const _ = this
    _.updatePanelContent(1, _.selectedProducts[1])
    _.updatePanelContent(2, _.selectedProducts[2])
    return _
  }

  updateSearchTemplate() {
    if (this.currentStep == 1) {
      setBYSSearchTemplate(Pura.BYS.panel_1_search_template)
      return
    }
    setBYSSearchTemplate(Pura.BYS.panel_2_search_template)
  }

  updatePreSelected(preSelected) {
    const _ = this
    const type = getURLParam('type') || false
    const cadence = getURLParam('cadence') || false

    preSelected.split(',').forEach((handle) => {
      searchProducts(handle, 'BYS-smartvial-SUB').then((res) => {
        const productId = $(res).find('.product-thumbnail').data('product-id')
        const productDataScript = $(res).find(`#product-data-${productId}`)
        const productData = JSON.parse(productDataScript.html())

        productData.purchaseType = type || 'SUB'
        productData.subFrequency = cadence || '1'
        _.selectedProducts[1].push(productData)
        _.updateHeaderContent()
      })
    })
  }

  // index are 1 and 2,
  // they don't start at 0 to keep naming consistent
  updatePanelContent(panelIndex, products) {
    let panel = $(`#BYS-panel-${panelIndex}`)
    let components = panel.find('[data-component="BYS-selected-product"]')
    let selectHeader = panel.find('.select-header')

    // hide or show "Select your fragrances" header
    if (products.length == 0) {
      selectHeader.show()
    } else {
      selectHeader.hide()
    }

    // reset all components
    components.removeClass('is-active')
    components.find('[data-content="fragrance-name"]').html('')
    components.find('.free-label').removeClass('hidden')

    // set the data for the selected product
    products.forEach((product, i) => {
      const element = components.eq(i)
      element.addClass('is-active')
      element.attr('data-handle', product.handle)
      element.find('img').attr('src', product.images[0])

      let titleElement = element.find('[data-content="fragrance-name"]')

      titleElement.html(product.title)

      element.find('.free-label').addClass('hidden')
    })

    // should we show continue / ATC button ?
    let maxProducts = Pura.BYS[`panel_${panelIndex}_max_products`]
    const button = panel
      .find('.panel-buttons button')
      .not('[data-action="open-modal"]')

    if (products.length == maxProducts) {
      if (Pura.BYS.toast_notification) {
        showToast(Pura.BYS.toast_notification)
      }
      button.removeAttr('disabled')
    } else {
      button.prop('disabled', true)
    }
  }

  gotoStep1() {
    const _ = this

    // update current step
    Pura.BYS.current_step = 1
    _.currentStep = 1

    $('[data-element="bys-panel-heading"]').html(Pura.BYS.panel_1_headline)
    updateTrademark()

    // set collection URL
    setCollectionHandle(Pura.BYS.panel_1_collection_handle)
    // clear filters and reload collection
    // clearFilters()

    _.updateSearchTemplate()

    setTimeout(() => {
      $('#BYS-panel-2').addClass('hidden')
      $('#BYS-panel-1').removeClass('hidden')
      $('[data-element="step-2-text"]').addClass('hidden')
      $('[data-element="step-1-text"]').removeClass('hidden')
      // clear search
      $('[data-action="search-bys"]').val('')
      $('[data-action="search-bys"]')
        .attr('data-fragrance-type', 'byb-smart')
        .attr('data-bys-panel', 1)
    }, 400)
  }

  gotoStep2() {
    const _ = this

    // update current step
    Pura.BYS.current_step = 2
    _.currentStep = 2

    _.updateSearchTemplate()

    const headline = Pura.BYS.panel_2_headline || Pura.BYS.panel_1_headline
    $('[data-element="bys-panel-heading"]').html(headline)
    updateTrademark()

    // set collection URL
    setCollectionHandle(Pura.BYS.panel_2_collection_handle)

    // clear filters and reload collection
    // clearFilters()

    // load entire price of step 1 products
    let step1TotalPrice = Pura.product.price
    let step1Products = _.selectedProducts[1]
    let product

    for (let i = 0; i < step1Products.length; ++i) {
      product = step1Products[i]
      // these are sub products
      if (product.purchaseType == 'SUB') {
        step1TotalPrice += product.price * 0.8
      } else {
        step1TotalPrice += product.price
      }
    }

    // apply sitewide discount but not to free sets
    // or collections where ignore_sitewide_discount == true
    if (
      Pura.cart.enable_sitewide_discount &&
      !Pura.BYS.ignore_all_discounting &&
      !Pura.BYS.panel_1_fragrances_are_free &&
      !Pura.BYS.panel_2_fragrances_are_free
    ) {
      let discountMultiplier = (100 - Pura.cart.discount_amount) / 100
      step1TotalPrice *= discountMultiplier
    }

    // format price
    step1TotalPrice = (step1TotalPrice / 100).toFixed(2)

    // change panel 0 price to display on panel 1
    $('#step-1-total-price').html(`$${step1TotalPrice}`)

    setTimeout(() => {
      $('#BYS-panel-1').addClass('hidden')
      $('#BYS-panel-2').removeClass('hidden')
      $('[data-element="step-1-text"]').addClass('hidden')
      $('[data-element="step-2-text"]').removeClass('hidden')
      // clear search
      $('[data-action="search-bys"]').val('')
      // set search type for this panel
      $('[data-action="search-bys"]')
        .attr('data-fragrance-type', 'byb-legacy')
        .attr('data-bys-panel', 2)
      // hideFilterPanel()
    }, 400)
  }

  addToCart(cartData) {
    const _ = this
    let groupID = generateUID()
    let discountCode = Pura.product.metafields.auto_apply_discount || false
    let bundleCartDisplay = Pura.product.metafields.bundle_home_car || false
    let subGroupID1 = '1_' + generateUID()
    let subGroupID2 = '2_' + generateUID()
    let orderIndex = 0
    const lockDuration = Pura.product.metafields.pura_lock_duration || false
    const hideBundleQty =
      Pura.product.metafields.pura_hide_bundle_quantity || false
    const lockCancellationVariant = 40866349023341
    const promoId = Pura.product?.metafields?.bys_promo_id || false

    let gwpProduct =
      Pura.productForm.gwp.hasGWPProduct === 'true'
        ? Pura.productForm.gwp.variantID
        : 'false'
    let currentDate = new Date().getTime()
    let gwpStartTime = Pura.productForm.gwp.startTime * 1000
    let gwpEndTime = Pura.productForm.gwp.endTime * 1000

    if (currentDate < gwpStartTime || currentDate > gwpEndTime) {
      gwpProduct = 'false'
    }

    let step1CartTag = 'Home'
    if (Pura.BYS.panel_1_image == 'Car') {
      step1CartTag = 'Car'
    }

    // get new packing Id with most recent cart
    let packingId = getNewPackingId(cartData)

    // Build the request object and add the step 1 device
    let request = {
      items: [
        {
          id: Pura.product.variants[0].id,
          quantity: 1,
          properties: {
            _pura_group_id: groupID,
            _pura_group_role: 'parent',
            _pura_display_order: orderIndex++,
            _pura_cart_heading: `${Pura.product.title} set includes:`,
            _pura_cart_heading_tag: step1CartTag,
            _pura_packing_id: packingId,
            _pura_full_price: (Pura.product.variants[0].price / 100).toFixed(2),
            _pura_full_price_currency: 'USD',
          },
        },
      ],
    }

    // add auto apply discount code if it exists
    if (discountCode) {
      request.items[0].properties._pura_auto_apply_discount = discountCode
    }

    if (bundleCartDisplay) {
      request.items[0].properties._pura_bundle_home_car = bundleCartDisplay
    }

    // add checkout savings message if it exists
    if (Pura.BYS.checkout_savings_message != null) {
      request.items[0].properties._pura_checkout_savings_message =
        Pura.BYS.checkout_savings_message
    }

    if (hideBundleQty) {
      request.items[0].properties._pura_hide_quantity = true
    }

    // apply lock duration if it exists
    if (lockDuration) {
      request.items[0].properties._pura_lock_duration = lockDuration
      request.items[0].properties._pura_hide_quantity = true
      if (promoId) {
        request.items[0].properties._pura_promo_id = promoId
      }
    }
    // Add step 1 items
    let newItem, intervalFrequency
    let selectedProducts = _.selectedProducts[1]
    let product

    for (let i = 0, n = selectedProducts.length; i < n; ++i) {
      product = selectedProducts[i]
      newItem = {
        id: product.variant_id,
        quantity: 1,
        properties: {
          _pura_group_id: groupID,
          _pura_group_role: 'child',
          _pura_UID: generateUID(),
          _pura_display_order: orderIndex++,
          _pura_full_price: (product.price / 100).toFixed(2),
          _pura_full_price_currency: 'USD',
          _pura_packing_id: packingId,
        },
      }

      // apply lock duration if it exists
      if (lockDuration) {
        newItem.properties._pura_lock_duration = lockDuration
        newItem.properties._pura_cancel_variant_id = lockCancellationVariant
        if (promoId) {
          newItem.properties._pura_promo_id = promoId
        }
      }

      // if it is a subscription item and we have the sub ID
      if (product.purchaseType == 'SUB' && product.type != 'Box') {
        // add selling plan id
        intervalFrequency = parseInt(product.subFrequency) - 1
        newItem.selling_plan = product.selling_plans[intervalFrequency].id
      }

      // push item to request array
      request.items.push(newItem)
    }
    // get new packing ID
    packingId = getNewPackingId(cartData, packingId)

    let step2CartTag = 'Home'
    if (Pura.BYS.panel_2_title == 'Car') {
      step2CartTag = 'Car'
    }

    // add step 2 device
    let panel2VariantID = Pura.BYS.panel_2_variant_id
    if (panel2VariantID != null) {
      request.items.push({
        id: panel2VariantID,
        quantity: 1,
        properties: {
          _pura_group_id: groupID,
          _pura_group_role: 'child',
          _pura_UID: generateUID(),
          _pura_cart_heading: `${Pura.BYS.panel_2_title} set includes:`,
          _pura_cart_heading_tag: step2CartTag,
          _pura_display_order: orderIndex++,
          _pura_packing_id: `${packingId}`,
        },
      })
    }

    // add step 2 fragrances
    selectedProducts = _.selectedProducts[2]

    for (let i = 0, n = selectedProducts.length; i < n; ++i) {
      product = selectedProducts[i]

      intervalFrequency = product.subFrequency || 1
      intervalFrequency = parseInt(intervalFrequency) - 1
      newItem = {
        id: product.variant_id,
        quantity: 1,
        properties: {
          _pura_group_id: groupID,
          _pura_group_role: 'child',
          _pura_UID: generateUID(),
          _pura_display_order: orderIndex++,
          _pura_packing_id: packingId,
          _pura_full_price: (product.price / 100).toFixed(2),
          _pura_full_price_currency: 'USD',
        },
      }

      // if it is a subscription item and we have the sub ID
      if (product.purchaseType == 'SUB' && product.type != 'Box') {
        // add selling plan id
        newItem.selling_plan = product.selling_plans[intervalFrequency].id
      }

      // push item to request array
      request.items.push(newItem)
    }

    if (gwpProduct !== 'false') {
      const headingMap = {
        7577030164589: 'Free Pura 4 Diffuser set:',
        7577030459501: 'Free Pura 4 Diffuser set:',
        7577030754413: 'Free Pura Car Diffuser set:',
      }

      const gwpLineItem = {
        id: gwpProduct,
        quantity: 1,
        properties: {
          _pura_group_id: groupID,
          _pura_group_role: 'child',
          _pura_UID: generateUID(),
          _pura_display_order: orderIndex++,
        },
      }

      if (headingMap[Pura.product?.id]) {
        gwpLineItem.properties._pura_cart_heading = headingMap[Pura.product?.id]
      }
      request.items.push(gwpLineItem)
    }

    let total = Pura.BYS.panel_1_max_products + 1
    total += Pura.BYS.panel_2_max_products + 1 || false
    if (gwpProduct !== 'false') total++

    if (request.items.length === total) {
      addItemsToCart(request)
        .then((res) => {
          // reset data and render
          _.selectedProducts = [[], [], []]
          _.gotoStep1()
          _.updateHeaderContent()
          cart.updateAndShow()
        })
        .catch((err) => {
          console.log('error adding boxset', err)
        })
    } else {
      $('[data-action="add-BYS-to-cart"]').prop('disabled', true)
    }
  } // END addToCart
} // END BYS

if (Pura.template.suffix == 'BYS') {
  const BYS = new BuildYourSet()
}
