import { NarvarParseResult } from '../../../types'
import { getCart } from './shopify-cart'

export function parseNarvarResponse(data: any): NarvarParseResult {
  const currentCart = data?.delivery_options?.cart?.[0] || {}
  const deliveryOptionName = currentCart.delivery_option_name || ''

  let description = currentCart.date_description || ''
  description = description
    .replace('deliveryOptionName', deliveryOptionName)
    .replace(/\{\{/g, '')
    .replace(/\}\}/g, '')
    .replace(/\s+/g, ' ')
    .trim()

  const narvarEstimate = description
  const puraEstimate = document.getElementById('estimate')?.textContent || ''
  const isMatch = puraEstimate === narvarEstimate

  return {
    narvarEstimate,
    puraEstimate,
    isMatch,
  }
}

export const queryNarvarShipping = async (
  zipCode: string,
  track: boolean
): Promise<void> => {
  const store = Pura?.shop_myshopify_url || ''
  if (!store) return

  const cart = await getCart()

  const narvarCart = {
    destination: {
      postal_code: zipCode,
      country: 'US',
    },
    region: 'US',
    language: 'en',
    display_location: 'pdp',
    channel: 'website',
    customer: {
      customer_id: Pura?.customer?.key || ' ',
      email: Pura?.customer?.email || ' ',
    },
    order_subtotal: {
      amount: parseFloat(cart?.total_price || '0') / 100,
      currency: 'USD',
    },
    items: [
      {
        sku: Pura?.product?.variants[0]?.sku || '',
        item_tags: Pura?.product?.tags || [],
        in_stock: !(Pura?.product?.tags || []).includes('__label:Notify me'),
        qty: 1,
        original_price: {
          amount: parseFloat(Pura?.product?.price || '0') / 100,
          currency: 'USD',
        },
        product_category: Pura?.product?.type || '',
      },
    ],
    session_id:
      Pura?.cart?.attributes?.narvar_promise_id ||
      Math.random().toString(36).substring(7),
  }

  const query = btoa(JSON.stringify(narvarCart))
  const extraParams = JSON.stringify({
    store: store,
    platform: 'shopify',
  })

  const params = new URLSearchParams()
  params.append('query', query)
  params.append('extra_params', extraParams)

  const url = `https://ship-cdn.domain-ship.prod20.narvar.com/api/v1/promise/options/shopify?${params.toString()}`

  try {
    const response = await fetch(url, {
      headers: {
        'Origin': window.location.origin,
        'Store': store,
      },
    })
    if (!response.ok) throw new Error('Narvar API request failed')

    const data = await response.json()
    if (!data) throw new Error('Narvar API request failed')

    const parsedData = parseNarvarResponse(data)

    if (track) {
      const segment = window?.analytics
      segment?.track('Narvar/Pura Estimated Delivery Date', {
        ...parsedData,
        zipCode,
        productTitle: Pura?.product?.title,
        productHandle: Pura?.product?.handle,
      })
    }
  } catch (error) {
    console.error('Failed to update Narvar shipping:', error)
  }
}
