import $ from 'cash-dom'

const updateSectionImages = () => {
  const $colorOptions = $('[data-component="color-swatches"] input[type="radio"]')
  const $selectedOption = $('[data-component="color-swatches"] input[type="radio"]:checked')

  const updateImageAttribute = ($img, attribute, selectedColor) => {
    let value = $img.attr(attribute)
    if (value && (value.includes('-white') || value.includes('-black'))) {
      value = value.replace(/-white|-black/g, `-${selectedColor}`)
      $img.attr(attribute, value)
    }
  }

  const updateImageSources = (color) => {
    if (color) {
      const selectedColor = color?.toLowerCase()
      const $images = $('.color-img')

      $images.each(function () {
        const $img = $(this)

        updateImageAttribute($img, 'src', selectedColor)
        updateImageAttribute($img, 'srcset', selectedColor)
      })
    }
  }

  if ($selectedOption.length) {
    updateImageSources($selectedOption.val())
  }

  $colorOptions.on('change', function () {
    if ($(this).is(':checked')) {
      updateImageSources($(this).val())
    }
  })
}

if (
  $('[data-section="whats-included"]').length ||
  $('[data-section="learn-more"]').length ||
  $('[data-section="comparison-column"]').length
) {
  updateSectionImages()
}

export { updateSectionImages }
