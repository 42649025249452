import { KlevuConfig } from '@klevu/core'

const initializeKlevu = () => {
  KlevuConfig.init({
    url: 'https://eucs30v2.ksearchnet.com/cs/v2/search',
    apiKey: import.meta.env.VITE_KLEVU_API,
  })
}

export { initializeKlevu }
