import $ from 'cash-dom'
import {
  loadImages,
  setSelectionPanelToCar,
  setSelectionPanelToHome,
  setSelectionPanelToLegacy,
  setSelectionPanelToSmartvial,
  updateBYSPanelPrice,
} from './bys-utils'

const selectionPanel = $('#product-selection-panel')
const selectionSlider = selectionPanel.find('#BYS-selection-slider')
const priceEle = selectionPanel.find('[data-content="BYS-selected-price"]')
const comparePriceEle = selectionPanel.find(
  '[data-content="BYS-selected-compare-price"]'
)

const carBadge = selectionPanel.find('.badge-car')
const homeBadge = selectionPanel.find('.badge-home')

const subOption = selectionPanel.find('#SUB-selection-form-option')
const otpOption = selectionPanel.find('#OTP-selection-form-option')

let selectionType, productData

const showSelectionPanel = (_productData, currentStep) => {
  productData = _productData

  const {
    title,
    description,
    vendor,
    type,
    hasOTP,
    hasSub,
    available,
    sub_variant_id,
    images,
    isSmartvial,
    isSingleProduct,
  } = productData

  // Tell the selection what type of options to display
  // SUB / OTP / Selection
  if (Pura.BYS) {
    selectionType = Pura.BYS.panel_1_selection_panel_type
    if (currentStep == 2) {
      selectionType = Pura.BYS.panel_2_selection_panel_type
    }
  } else {
    selectionType = 'Selection'
  }

  if (!hasSub) {
    selectionType = 'OTP'
  }

  if (!hasOTP) {
    selectionType = 'SUB'
  }

  setPanelSelectionType(selectionType)

  if (type == 'Car Fragrance') {
    setSelectionPanelToCar(homeBadge, carBadge)
  } else {
    setSelectionPanelToHome(homeBadge, carBadge)
  }

  if (isSmartvial) {
    setSelectionPanelToSmartvial(selectionPanel)
  } else {
    setSelectionPanelToLegacy(selectionPanel)
  }

  updateBYSPanelPrice(productData, comparePriceEle, priceEle)

  // set product info
  selectionPanel.find('[data-content="BYS-selected-title"]').html(title)
  selectionPanel.find('[data-content="BYS-selected-vendor"]').html(vendor)
  selectionPanel
    .find('[data-content="BYS-selected-description"]')
    .html(description.replace('<p>', '').replace('</p>', ''))

  // is product available ?
  if (available) {
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .removeAttr('disabled')
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .attr('data-variant-id', sub_variant_id)
  } else {
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .prop('disabled', true)
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .attr('data-variant-id', '')
  }

  if (isSingleProduct) {
    selectionPanel
      .find('[data-action="add-selected-product-to-BYS"]')
      .text('Add to cart')
      .attr('data-action', 'add-selected-product-to-cart')
  }

  loadImages(images, selectionSlider, selectionPanel, title)
}

const setPanelSelectionType = (panelType) => {
  const isFreeDevice = Pura.product?.id === 7483942109293 || false
  switch (panelType) {
    case 'SUB':
      if (!isFreeDevice) {
        selectionPanel.find('#product-selection-panel-options').show()
      }
      subOption.show()
      subOption.find('#purchase-type-sub').prop('checked', true)
      selectionPanel.find('[name="purchase-type"]').hide()
      otpOption.hide()
      break

    case 'OTP':
      otpOption.show().css('display', 'flex')
      otpOption.find('#purchase-type-otp').prop('checked', true)
      otpOption.removeClass('border-t')
      selectionPanel.find('[name="purchase-type"]').hide()
      subOption.hide()
      selectionPanel.find('#product-selection-panel-options').hide()
      break

    default:
      selectionPanel.find('#product-selection-panel-options').show()
      otpOption.show().addClass('border-t').css('display', 'flex')
      subOption.show()
      selectionPanel.find('[name="purchase-type"]').show()
      subOption.find('#purchase-type-sub').prop('checked', true)
  }
}

const hideSelectionPanel = () => {
  selectionPanel[0].hide()
}

selectionPanel.find('[name="purchase-type"]').on('change', (e) => {
  updateBYSPanelPrice(productData, comparePriceEle, priceEle)
})

export { showSelectionPanel, hideSelectionPanel }
