import { IpapiResponse } from '../../../types'

export const getUserZipCode = async (): Promise<string | null> => {
  try {
    const response = await fetch(
      `https://api.ipapi.is/?key=${import.meta.env.VITE_IPAPI_KEY}`
    )
    const data = (await response.json()) as IpapiResponse
    const { zip } = data.location

    return zip
  } catch (error) {
    console.warn('Could not get zip from ipapi:', error)
    return null
  }
}
