import $ from 'cash-dom'

export const initScrollMenu = () => {
  const currentUrl = window.location.href

  $('[data-component="app-scroll-menu"] li a').each(
    function handleActiveLink() {
      const link = $(this)
      const href = link.attr('href')
      const fullUrl = href?.startsWith('https://')
        ? href
        : `https://${Pura?.shop_domain}${href}`

      if (currentUrl === fullUrl) {
        link.removeClass('opacity-60').addClass('border-b')
      }
    }
  )
}

if (Pura?.isInApp) {
  initScrollMenu()
}
