import $ from 'cash-dom'
import { productPricing } from './product-pricing'
import {
  buildCollectionURL,
  isFreePricingApplicable,
} from '../pages/collection'

const loadImages = (images, selectionSlider, selectionPanel, title) => {
  selectionSlider.slick('slickRemove')

  images.forEach((obj, i) => {
    selectionSlider.slick(
      'slickAdd',
      ` <div class="leading-0">
          <img alt="${
            title
              ? `${title.toLowerCase()} view ${i + 1}`
              : `image view ${i + 1}`
          }" src="${obj}"
	          class="h-[100vw] w-full md:h-full object-cover" >
	      </div>`
    )
  })

  setTimeout(() => {
    selectionPanel[0].show()
  }, 100)
}

const setSelectionPanelToCar = (homeBadge, carBadge) => {
  homeBadge.hide()
  carBadge.show()
}

const setSelectionPanelToHome = (homeBadge, carBadge) => {
  homeBadge.show()
  carBadge.hide()
}

const setSelectionPanelToLegacy = (selectionPanel) => {
  selectionPanel.find('.smart-vial-messaging').hide()
}

const setSelectionPanelToSmartvial = (selectionPanel) => {
  selectionPanel.find('.smart-vial-messaging').show()
}

const updateBYSPanelPrice = (product, comparePriceEle, priceEle) => {
  const bundleTags = Pura.product?.tags?.join(', ') || false
  const ignoreAllDiscounting = Pura.BYS?.ignore_all_discounting || false
  const freeItem = isFreePricingApplicable(buildCollectionURL()) || false

  const { subscriptionPrice, oneTimePurchasePrice, comparePrice } =
    productPricing(product, bundleTags, ignoreAllDiscounting)

  const purchaseType = $(
    '#product-selection-panel [name="purchase-type"]:checked'
  ).val()

  const finalPrice =
    purchaseType === 'SUB' ? subscriptionPrice : oneTimePurchasePrice

  const finalComparePrice = freeItem
    ? Math.round(product.price * 0.8 * 100) / 100
    : comparePrice

  priceEle.html(
    finalPrice === 0 || freeItem ? 'FREE' : `$${finalPrice.toFixed(2)}`
  )

  if (finalComparePrice > finalPrice || freeItem) {
    comparePriceEle.html(`$${finalComparePrice.toFixed(2)}`).show()
  } else {
    comparePriceEle.hide()
  }
}

export {
  loadImages,
  setSelectionPanelToCar,
  setSelectionPanelToHome,
  setSelectionPanelToLegacy,
  setSelectionPanelToSmartvial,
  updateBYSPanelPrice,
}
