import $ from 'cash-dom'
import { startCountdown } from './countdown-timer'

function shouldDisplayAnnouncement(type) {
  return (
    (type === 'app' && window.Pura?.isInApp) ||
    (type === 'web' && !window.Pura?.isInApp)
  )
}

const checkForPause = ($container, $slides, isHovered) => {
  if (isHovered) {
    $slides.each((_, slide) => {
      const $slide = $(slide)
      const opacity = parseFloat(window.getComputedStyle(slide).opacity)

      if (opacity > 0.7) {
        $container.addClass('paused')
        $slide.css('opacity', '1')
      }
    })
  }
}

$(document).ready(() => {
  const $announcementBars = $(
    '[data-element="announcement-bar"], [data-element="announcement-bar-app"]'
  )

  $announcementBars.each((_, announcementBar) => {
    const $announcementBar = $(announcementBar)
    const $slides = $announcementBar.find(
      '[data-component="announcement-bar-slide"]'
    )

    if ($slides.length > 0) {
      let checkInterval
      let isHovered = false
      let visibleSlides = 0

      $slides.each((_s, slide) => {
        const $slide = $(slide)
        if (shouldDisplayAnnouncement($slide.data('type'))) {
          visibleSlides++
        } else {
          $slide.remove()
        }
      })

      const $remainingSlides = $announcementBar.find(
        '[data-component="announcement-bar-slide"]'
      )

      $remainingSlides.each((_s, slide) => {
        const $slide = $(slide)
        $slide.removeClass('invisible')

        if (visibleSlides === 1) {
          $slide.css({
            opacity: 1,
            zIndex: 10,
          })
        }

        const $countdown = $slide.find('[data-element="countdown"]')
        if ($countdown.length > 0) {
          startCountdown($countdown[0], 'inline')
        }

        $slide.on('animationiteration', () => {
          if (!isHovered) $slide.css('opacity', '')
        })
      })

      if (visibleSlides > 1) {
        $announcementBar.on('mouseenter', () => {
          isHovered = true
          if (!checkInterval) {
            checkInterval = setInterval(
              () =>
                checkForPause($announcementBar, $remainingSlides, isHovered),
              100
            )
          }
        })

        $announcementBar.on('mouseleave', () => {
          isHovered = false
          $announcementBar.removeClass('paused')

          $remainingSlides.each((_s, slide) => {
            $(slide).css('opacity', '')
          })

          clearInterval(checkInterval)
          checkInterval = null
        })
      }
    }
  })
})
