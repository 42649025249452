import {
  KlevuFetch,
  KlevuKMCSettings,
  search,
  sendSearchEvent,
} from '@klevu/core'
import { initializeKlevu } from './klevu-init'

const searchProductsKlevu = async (query) => {
  initializeKlevu()

  const result = await KlevuFetch(
    search(
      query,
      {
        groupCondition: {
          groupOperator: 'ANY_OF',
          conditions: [
            {
              'key': 'tags',
              'valueOperator': 'EXCLUDE',
              'singleSelect': false,
              'values': ['type_Legacy'],
            },
          ],
        },
        fields: [
          'sku',
          'brand',
          'product_not_available',
          'type',
          'price',
          'id',
          'imageHover',
          'inStock',
          'itemGroupId',
          'image',
          'name',
          'tags',
          'url',
          'custom_media_display',
        ],
        limit: 6,
      },
      sendSearchEvent()
    )
  )
  return result
}

const klevuSettings = async () => {
  initializeKlevu()

  const settings = await KlevuKMCSettings()
  return settings
}

export { searchProductsKlevu, klevuSettings }
