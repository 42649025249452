import $ from 'cash-dom'
import { buildReviews } from './yotpo-ratings'
import {
  getCollection,
  getProductRecommendations,
} from '../resources/klevu-api'
import { addToCartButton } from './product-form'
import { productGrid } from '../templates/klevu-product-grids'
import { getKlevuBadge, getKlevuCategoryBadge } from './klevu-badges'
import { buildLazyLoad } from './video'
import { productPricing } from './product-pricing'

const buildSlider = (products, slider, type) => {
  const maxItems = 12
  const sliderProducts = products.slice(0, maxItems)

  const sliderItems = sliderProducts.map((item) => {
    const { subscriptionPrice, oneTimePurchasePrice, comparePrice } =
      productPricing(item)

    const badge = getKlevuBadge(item)
    const categoryBadges = getKlevuCategoryBadge(item)

    const priceToShow = subscriptionPrice || oneTimePurchasePrice //
    let showComparePrice = false

    if (
      comparePrice &&
      comparePrice > priceToShow &&
      comparePrice !== oneTimePurchasePrice
    ) {
      showComparePrice = true
    }

    return productGrid(
      item,
      priceToShow,
      showComparePrice ? comparePrice : null,
      badge,
      categoryBadges,
      type,
      slider.hasClass('add-to-cart-button') ? 'isActive' : null,
      slider.hasClass('enable_reviews') ? 'isActive' : null,
      !item.tags.includes('hide_Price'),
      !item.tags.includes('hide_Reviews')
    )
  })

  slider.slick('removeSlide')
  slider.slick(
    'slickAdd',
    sliderItems.map((html) => `<div><div>${html}</div></div>`).join('')
  )
}

$('.klevu-collection-section-slider').each(
  function klevuCollectionSectionSlider() {
    const collectionID = $(this).attr('data-id').replace(/-/g, ' ')

    getCollection(collectionID).then((products) => {
      const collectionProducts = products.queriesById('collection')?.records

      const slider = $(this).slider()
      slider.slick('removeSlide')

      buildSlider(collectionProducts, slider, collectionID)
      buildReviews()
      addToCartButton()
      if (!window.Pura.isInApp) {
        buildLazyLoad()
      }
    })
  }
)

const buildRecommendationSlider = async () => {
  try {
    const res = await getProductRecommendations()
    const result = res.queriesById('productRecs')
    const products = res.queriesById('productRecs').records
    if (products.length === 0) return

    const recommendationClickManager = result.getRecommendationClickSendEvent()
    const slider = $('#recommendation-section-slider')

    $('#product-recommendations').removeClass('hidden')
    buildSlider(products, slider, 'productRecs')
    buildReviews()
    addToCartButton()
    if (!window.Pura.isInApp) {
      buildLazyLoad()
    }

    $('[data-element="recommended-products"]').each(
      function setupRecommendedProducts() {
        $(this).on('click', function handleClickEvent() {
          const productId = this.getAttribute('data-id')
          const productGroupId = this.getAttribute('data-group-id')
          recommendationClickManager(productId, productGroupId)
        })
      }
    )
  } catch (e) {
    console.log(e)
  }
}

if ($('#recommendation-section-slider').length > 0) {
  buildRecommendationSlider()
}
