import $, { Cash } from 'cash-dom'
import { reInitSliders } from './sliders'
import { handleSwatchClick } from './product-options'
import { productPricing } from './product-pricing'

const openProductPageModal = (modal: Cash, handle: string, product: any) => {
  const bundleTags = Pura?.product?.tags?.join(', ') || false
  const ignoreAllDiscounting = Pura?.BYS?.ignore_all_discounting || false

  const purchaseType = product.purchaseType ?? 'OTP'
  const { subscriptionPrice, oneTimePurchasePrice, comparePrice } =
    productPricing(product, bundleTags, ignoreAllDiscounting)

  const modalData = $(modal).find('[data-element="modal-data"]')
  modalData.empty()

  if (!handle) return

  let selectedSwatch: string | undefined
  if (handle === 'pura-plus') {
    selectedSwatch = Pura?.product?.handle.includes('black') ? 'Black' : 'White'
  }

  fetch(handle as string)
    .then((response) => response.text())
    .then((html) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')
      const content = doc.querySelector('main')

      if (content) {
        const modalPanels = $(content).find('modal-panel')
        modalPanels.each((i, modalPanel) => {
          modalPanel.remove()
        })

        $(content).find('[data-component="featured-collections"]').remove()

        const removeAlergens = $(content).find(
          '[data-modal-id="view_allergens_modal"]'
        )
        removeAlergens.each((i, allergens) => {
          allergens.remove()
        })
        const reviewsAnchor = $(content)
          .find('[data-element="reviews"]')
          .closest('a')
        const reviewLayout = reviewsAnchor.html()
        reviewsAnchor.replaceWith(
          `<div class="flex items-center gap-1" data-element="reviews">${reviewLayout}</div>`
        )

        const priceEl = $(content).find(
          '[data-component="product-info-panel"] [data-content="product-price"]'
        )

        const priceComponents = priceEl.find('span')
        const hasStrikePrice = priceComponents.length > 1
        const originalPriceEl = priceComponents.first()
        const discountedPrice =
          purchaseType === 'OTP' ? oneTimePurchasePrice : subscriptionPrice || 0
        const formattedDiscountPrice = product.title.includes('Free')
          ? 'FREE'
          : `$${discountedPrice?.toFixed(2)}`

        originalPriceEl.text(formattedDiscountPrice).attr({
          'data-tag': formattedDiscountPrice,
          'aria-label': `subscription price is: ${formattedDiscountPrice}`,
        })

        const comparePriceValue = parseFloat(comparePrice.toString())

        if (
          discountedPrice === comparePriceValue &&
          formattedDiscountPrice !== 'FREE'
        ) {
          priceComponents.eq(1).remove()
        } else if (
          formattedDiscountPrice === 'FREE' ||
          (!hasStrikePrice && discountedPrice < comparePriceValue)
        ) {
          priceEl.append(
            `<span aria-label="original price is: $${comparePrice}" class="line-through opacity-50">$${comparePrice}</span>`
          )
        }

        const btsCTA = $(content).find('#build-your-set-cta')
        const btsCTA2 = $(content).find('#build-your-set-cta-variant-2')
        const productForm = $(content).find('[data-component="product-form"]')
        const productLink = $(content).find('[data-component="product-link"]')

        btsCTA.remove()
        btsCTA2.remove()
        productForm.remove()
        productLink.remove()

        modalData.html(content.innerHTML)

        modal.attr('aria-hidden', 'false')

        if (handle === 'pura-plus') {
          setTimeout(() => {
            const swatches = $(
              '[data-component="color-swatches"] input[type="radio"]'
            )
            handleSwatchClick(swatches)
            swatches
              .filter(`[value="${selectedSwatch}"]`)
              .prop('checked', true)
              .trigger('change')
          }, 200)
        }

        $('[data-component="slider"]').each(function sliderInit() {
          reInitSliders($(this))
        })
      } else {
        modalData.html('<p>Error: Content not found.</p>')
      }
    })
    .catch(() => {
      return null
    })
}

export { openProductPageModal }
